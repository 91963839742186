<template>
  <v-layout
    v-if="userData.is_registered === 'False'"
    justify-center
    mt-5
  >
    <v-card
      class="card-finish-register"
      style="max-width:390px;"
    >
      <v-container>
        <v-layout>
          <p
            class="text-title"
          >
            Terminar de registrarme
          </p>
        </v-layout>
        <v-layout
          row
          wrap
        >
          <v-flex
            xs12
            mt-2
            :style=" fnameErrors.length > 0 ? 'margin-bottom: 13px' : ''"
          >
            <v-text-field
              v-model="firstname"
              type="text"
              name="firstname"
              autocomplete="given-name"
              label="Nombre"
              class="inputs"
              full-width
              :error-messages="fnameErrors"
            />
          </v-flex>
          <v-flex
            xs12
            mt-3
            :style=" lnameErrors.length > 0 ? 'margin-bottom: 13px' : ''"
          >
            <v-text-field
              v-model="lastname"
              type="text"
              name="lastname"
              autocomplete="family-name"
              label="Apellido"
              class="inputs"
              full-width
              :error-messages="lnameErrors"
              :hide-details="lnameErrors.length === 0"
            />
          </v-flex>
          <v-flex mt-2>
            <v-layout>
              <v-checkbox
                v-model="is_terms_and_privacy"
                color="info2"
                height="0"
              />
              <v-flex
                xs12
              >
                <p class="terms-privacy">
                  Acepto las
                  <!--<router-link
                    :to="{name: 'privacy-policy'}"
                    target="_blank"
                    style="text-decoration: none; color: #154360"
                  >
                    <b style="color: #30B0BF">
                      Condiciones de uso
                    </b>
                  </router-link>y las
                  -->
                  <router-link
                    :to="{name: 'privacy-policy'}"
                    target="_blank"
                    style="text-decoration: none; color: #154360"
                  >
                    <b style="color: #30B0BF">
                      Políticas de privacidad
                    </b>
                  </router-link>
                  de Mandomedio.
                </p>
              </v-flex>
            </v-layout>
            <v-flex
              v-for="(item, index) in is_terms_and_privacy_errors"
              :key="index"
            >
              <p
                class="ml-3 terms-privacy-error"
              >
                {{ item }}
              </p>
            </v-flex>
          </v-flex>
          <v-flex
            xs12
          >
            <v-btn
              style="border-radius: 5px;height: 50px;"
              block
              color="info2"
              type="submit"
              @click.prevent="authenticate"
            >
              <p class="button-text">
                Ingresar
              </p>
            </v-btn>
          </v-flex>
          <v-flex
            mt-3
            xs12
          >
            <v-divider />
          </v-flex>
          <v-flex mt-3>
            <p class="text-notification-google">
              Mandomedio te enviará ofertas laborales, tips y consejos a
              tu correo electrónico. Puedes desactivar estas notificaciones
              en el panel de cofiguraciones de tu cuenta.
            </p>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-layout>
</template>

<script>
import axios from 'axios';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';

export default {
  mixins: [JWTConfigMixin],
  props: {
    userData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      access_token: '',
      final_query: { is_registered: false },
      menu2: false,
      respErrors: null,
      date: '',
      country: 'CL',
      countryLive: 'CL',
      region: '',
      firstname: '',
      lastname: '',
      city: '',
      phone: '',
      address: '',
      genero: '',
      studies: 0,
      idnumber: '',
      itemsG: [
        { text: 'Masculino', value: 1 },
        { text: 'Femenino', value: 2 },

      ],
      cvName: '',
      cvFile: null,
      lastCompany: '',
      lastPosition: '',
      errorchip: false,
      chipmsg: '',
      dialog_finish_register: true,
      is_terms_and_privacy: false,
      is_terms_and_privacy_errors: [],
      fnameErrors: [],
      lnameErrors: [],
    };
  },
  watch: {
    menu2(val) {
      if (val) {
        setTimeout(() => { this.$refs.picker.activePicker = 'YEAR'; });
      }
    },
  },
  mounted() {
    if (this.userData.is_registered === 'True') {
      this.login();
    } else {
      this.firstname = this.userData.firstName;
      this.lastname = this.userData.lastName;
    }
  },
  methods: {
    authenticate() {
      this.fnameErrors = [];
      this.lnameErrors = [];
      this.is_terms_and_privacy_errors = [];
      this.$store.commit('setLoading');
      const form = new FormData();
      form.append('username', this.userData.email);
      form.append('password', this.code);
      form.append('password_conf', this.code);
      form.append('first_name', this.firstname);
      form.append('last_name', this.lastname);
      form.append('email', this.userData.email);
      form.append('nationality', this.country);
      form.append('city', this.city);
      form.append('phone_number', this.jointNumber);
      form.append('cv', this.cvFile);
      form.append('studies', this.studies);
      form.append('profile_pic_url', decodeURIComponent(this.userData.pfpUrl));
      form.append('is_terms_and_privacy', this.is_terms_and_privacy);

      const headers = {
        'Content-Type': 'multipart/form-data; boundary = &',
      };

      axios.post(this.$store.state.endpoints.candidates.register, form, { headers })
        .then(() => {
          this.$store.commit('unsetLoading');
          this.login();
        })
        .catch(e => {
          this.errorchip = true;
          if (e.response.data.non_field_errors) {
            this.chipmsg = e.response.data.non_field_errors.pop();
          } else {
            if (e.response.data.first_name) {
              this.fnameErrors = [...e.response.data.first_name];
            }
            if (e.response.data.last_name) {
              this.lnameErrors = [...e.response.data.last_name];
            }
            if (e.response.data.is_terms_and_privacy) {
              this.is_terms_and_privacy_errors = [...e.response.data.is_terms_and_privacy];
            }
            this.chipmsg = 'Por favor verifique los datos ingresados en el formulario.';
          }
          this.$store.commit('unsetLoading');
        });
    },
    login() {
      this.$store.commit('setLoading');
      this.errorchip = false;

      const payload = {
        username: this.userData.email,
      };

      axios.post(this.$store.state.endpoints.linkedin.callback, payload)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', false);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');
                this.$router.push({ name: 'candidate:offers', params: { geo: 'CL' } });
              });
          } else {
            this.$store.commit('unsetLoading');
            this.$router.push({ name: 'candidate:offers', params: { geo: 'CL' } });
          }
        })
        .catch(() => {
          this.$store.commit('unsetLoading');
          this.$router.push({ name: 'candidate:offers', params: { geo: 'CL' } });
        });
    },

  },
};
</script>

<style scoped>
.card-finish-register {
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 15px 20px 0 rgba(0,0,0,0.11);
}
.text-title {
  height: 32px;
  width: 198px;
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.14px;
  line-height: 32px;
}
.inputs {
  box-sizing: border-box;
  height: 55px;
  border: 1px solid rgba(0,0,0,0.23);
  border-radius: 4px;
  margin: 0px;
}
.terms-privacy {
  color: rgba(0,0,0,0.87);
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0.29px;
  line-height: 14px;
  margin-top: 13px;
}
.button-text {
  color: #FFFFFF;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-transform: initial;
  margin: 0px;
}
.text-notification-google {
  color: rgba(0,0,0,0.6);
  font-family: Lato;
  font-size: 10px;
  letter-spacing: 0.11px;
  line-height: 13px;
  text-align: justify;
}
.terms-privacy-error{
  color: #FF5252;
  font: 12px lato;
}
</style>
